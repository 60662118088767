<template>
  <div class="main page-container">
    <Breadcrumb></Breadcrumb>

    <patientinfocard :patient.sync="patientInfo"></patientinfocard>
    <div class="formregion_warpper">
      <div class="formgion">
        <div class="nodataShow" v-if="conclusion===''&&imageList.length===0">暂无复查详情</div>
        <div class="formgion_item" v-else>
          <div class="title">检查项:</div>
          <div class="reviewdetails-box">
            <div class="formgion_item _flex">
              <viewer class="image_group" v-for="(item,index) in imageList" :key="index">
                <div style="margin-right: 10px">{{item.checkname}}:</div>
                <img :src="i" alt="" class="image" v-for="(i,_index) in item.urls" :key="_index">
              </viewer>
            </div>
          </div>
        </div>
        <div class="formgion_item">
          <div class="title">医助结论:</div>
          <div class="conclusion">{{conclusion}}</div>
          <div v-if="conclusion==''" class="nodata">暂无医助结论</div>
        </div>
        <div class="formgion_item">
          <div class="title">工单记录:</div>
          <el-table
              :data="tableData"
              border
              size="mini"
              style="width: 100%">
            <el-table-column
                prop="createTime"
                label="创建时间"
                width="180">
              <template slot-scope="socpe">{{
                  socpe.row.createTime | dateFormat("YYYY-MM-DD HH:mm:ss")
                }}</template>
            </el-table-column>
            <el-table-column
                prop="problemDescription"
                label="问题描述"
            >
            </el-table-column>
            <el-table-column
                prop="assistantConclusion"
                label="医助处理结果">
            </el-table-column>
          </el-table>
        </div>

      </div>
      <div class="formgion-left">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="呼叫" name="first">
            <div class="relationsPhoneList">
              <div class="title" v-for="(item, index) in relationsList" :key="index">
                <div>{{ item.name }} :{{ item.phone }}</div>
                <span @click="telNum(item.phone)" style="cursor: pointer">呼叫</span>
              </div>
            </div>
            <el-form :model="teachFrom" ref="teachFromRef" label-width="80px">
              <el-form-item label="是否应答" prop="isAnswer" label-width="100px">
                <el-radio v-model="teachFrom.isAnswer" :label="1">是</el-radio>
                <el-radio v-model="teachFrom.isAnswer" :label="0" :disabled="disable">否</el-radio>
              </el-form-item>
              <el-form-item label="下次提醒时间" prop="nextTime" label-width="100px" v-show="teachFrom.isAnswer===0">
                <div class="teachTime-box">
                  <el-date-picker
                      style="margin-bottom: 12px"
                      v-model="teachFrom.nextTime"
                      type="date"
                      :picker-options="pickerOptions"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                  </el-date-picker>
                  <el-time-picker
                      v-model="teachFrom.handM"
                      value-format="HH:mm:ss"
                      placeholder="选择任意时间点">
                  </el-time-picker>
                </div>
              </el-form-item>
            </el-form>
            <div class="btngroups" v-show="teachFrom.isAnswer===0">
              <el-button @click="cancelbtn">取 消</el-button>
              <el-button type="primary" @click="confirmEvent(0)">确 定</el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane label="添加工单" name="second">
            <el-form :model="teachFrom" ref="teachFromRef" label-width="80px">
              <el-form-item label="医生工作室" prop="office" label-width="100px" >
                <el-input v-model="patientInfo.groupName" disabled></el-input>
              </el-form-item>
              <el-form-item label="患者姓名" prop="userName" label-width="100px" >
                <el-input v-model="patientInfo.userName" disabled></el-input>
              </el-form-item>
              <el-form-item label="事件描述" prop="des" label-width="100px">
                <el-input
                    v-model="teachFrom.des"
                    type="textarea"
                    :maxlength="100"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="btngroups">
              <el-button @click="cancelbtn">取 消</el-button>
              <el-button type="primary" @click="confirmEvent(1)">确 定</el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import patientinfocard from "@/views/taskcenter/components/patientinfocard";
import {Debounce} from "../../tools/utils";
export default {
  name: "reviewdetails",
  components:{patientinfocard},
  data(){
    return{
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      patientInfo:{},
      questionList:[],
      //提交表单参数
      map:[],
      isDateList:false,
      imageList:[],
      conclusion:'',
      activeName:'first',
      relationsList:[],
      teachFrom: {
        teachId: '',
        userId: '',
        isAnswer: 1,
        des: '',
        teachTime: '',
        name: '',
        office:'',
        nextTime:'',
        handM: ''
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();  //如果没有后面的-8.64e7就是不可以选择今天的
        }
      },
      userInfo:{},
      tableData: [],
      reviewId:'',
      userId:'',
      disable:false,
      myDeBonce: "",

    }
  },
  created() {
    this.myDeBonce = new Debounce(500)
    this.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    this.reviewId = this.$route.query.reviewId
    this.disable = this.$route.query.type === 0
    this.userId = this.$route.query.userId
    this.reviewList(this.reviewId)
    this.getPatienInfo(this.userId)
    this.getRelationsList(this.userId)
    this.getOrderTableList(this.userId,this.reviewId)
  },
  methods:{
    //获取随访问题详情列表
    async reviewList(reviewId){
      const params = {
        reviewId,
      }
      const res = await this.$http.getReviewDetails(params)
      if (res.result.data.conclusion){
        this.conclusion = res.result.data.conclusion.conclusion
      }else {
        this.conclusion=''
      }
      console.log(res,'================打印结果')
      if (res.result.data.listmap){
        this.imageList=this.dealUrlList(res.result.data.listmap)
      }else {
        this.imageList=[]
      }
    },

    //获取患者信息
    async getPatienInfo(id){
      const params = {
          userId:id
      }
      const res = await this.$http.getPatientInfoMation(params)
      console.log(res)
      res.result.data.age = res.result.data.birth ? this.$moment().diff(res.result.data.birth, 'years') : ''
      console.log(res.result.data.age)
      if (res.result.data.gender ==1){
        res.result.data.gender='男'
      }else if (res.result.data.gender ==2){
        res.result.data.gender='女'
      }else {
        res.result.data.gender=''
      }
      this.patientInfo = res.result.data
    },
    //处理数组
    dealUrlList(urllist){
      let orginarr = []
      for (let i in urllist) {
        orginarr.push(urllist [i])
      }
      console.log(urllist)
      let idArrs = orginarr.map(item=>{
        const urls = item.filter((_item,index)=>{
          return index>0
        })
        return {
          checkname:item[0],
          urls:urls
        }
      })
      return idArrs
    },
    handleClick(){
      console.log(this.activeName)
    },
    //获取当前患者通讯录
    getRelationsList(val){
      let params = {
        userId: val,
      };
      this.$http.getRelationsPhone(params).then((res) => {
        console.log(res);
        this.relationsList = res.result.data;
      });
    },
    cancelbtn(){
      this.teachFrom.handM=''
      this.teachFrom.nextTime=''
      this.teachFrom.des=''
    },
    confirmEvent(type){
      if (type===1){
        this.addOrderConfirm()
      }else {
        this.updateFollowUpReq()
      }
    },
    //下次提醒
    async updateFollowUpReq(){
      await this.myDeBonce.debounceEnd()

      if (this.teachFrom.nextTime==='' || this.teachFrom.handM==='') return this.$message.error("请先填写下次提醒时间");
      let params = {
        reviewId: this.reviewId,
        isAnswer: 0,
        nextTime:this.teachFrom.nextTime + ' ' + this.teachFrom.handM,
      };
      console.log(params)
      console.log(this.teachFrom.nextTime)
      console.log(this.teachFrom.handM)
      const res = await this.$http.updateRemindReview(params);
      if (res.result.msg === "success") {
        this.$message.success("处理成功!");
      } else {
        this.$message.error("处理失败!");
      }
    },
    //确认添加工单
    async addOrderConfirm() {
      await this.myDeBonce.debounceEnd()
      //隐藏对话框
      if (this.teachFrom.des === "") return this.$message.error("请先填写事件描述");
      let params = {
        followUpId: this.reviewId,
        customerServerId: this.userInfo.customerServerId,
        groupId: this.patientInfo.groupId,
        userId: this.userId,
        problemDescription: this.teachFrom.des,
        assistantId: this.patientInfo.assistantId,
        workOrderType: 1,
      };
      //添加工单请求
      const res = await this.$http.addWorkOrder(params);
      console.log(params);
      console.log(res, "请求结果=========================");
      if (res.result.msg == "success") {
        this.$message.success("添加成功!");
        this.teachFrom.des = ''
        this.getOrderTableList(this.userId)
      } else {
        this.$message.error("添加失败!");
      }
    },
    //获取工单信息
    async getOrderTableList(userId,followUpId){
      let params = {
        customerServerId: this.userInfo.customerServerId,
        workOrderType: 1,
        userId,
        followUpId
      };
      //获取工单信息
      const res = await this.$http.getWorkManageInfo(params);
      this.tableData = res.result.data
      console.log(params);
      console.log(res, "请求结果=========================");

    },
    telNum(phone) {
      ClinkAgent.previewOutcall({ tel: phone });
    },
  }
}
</script>

<style scoped lang="less">
.main{
  position: relative;
}
.formgion{
  width: 100%;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.formgion::-webkit-scrollbar {display:none}
.formgion-left{
  width: 35%;
  box-sizing: border-box;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  margin-left: 16px;
  overflow-y: auto;
  overflow-x: hidden;
}
.formgion-left::-webkit-scrollbar {display:none}
.formregion_warpper{
  overflow: hidden;
  margin-top: 10px;
  display: flex;
  max-height: 680px;
  justify-content: space-between;
}
.nodataShow{
  width: 500px;
  height: 100px;
  margin: 0 auto;
  font-size: 30px;
  font-weight: bold;
  color: dimgrey;
  text-align: center;
}
.el-form{
  margin: 0 20px 0;
}
.title{
  color: #0099CC;
  margin-bottom: 20px;
  font-size: 24px;
}
.image{
  width: 200px;
  height: 200px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.image_group{
  display: flex;
  flex-wrap: wrap ;
  margin-bottom: 10px;
}
.subsquestion_box{
  margin: 20px 20px 0;
}
.formgion_item{
  margin-bottom: 20px;
}
._flex{
  display: flex;
  flex-wrap: wrap;
}
.nodata{
  color: #606266;
  font-size: 24px;
}
.reviewdetails-box{
  margin-left: 20px;
}
.relationsPhoneList{
  margin-bottom: 12px;
  .title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    text-align: right;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    span {
      color: #409eff;
    }
    div:first-child{
      padding-left: 46px;
    }
  }
}
.teachTime-box{
  display: flex;
  flex-direction: column;
}
/deep/.el-form{
  margin: 0;
}
.btngroups{
  text-align: center;
}
</style>